import React from 'react';
import { navigate } from 'gatsby';
import Layout from '../components/Layout';
import Button from '../components/Button/Button';
import GlobalWrapper from '../components/GlobalWrapper/GlobalWrapper';
import * as styles from '../styles/pages/shortHeightContent.module.css';

import Footnotes from '../components/Footnotes/Footnotes';

import { GtagHead } from '../components/GTAG';

export function Head() {
  return (
    <>
      <GtagHead id='gtag' />
      <title>LOQTORZI™ - 404 page</title>
      <meta name='description' content='404 page description'/>
    </>
  )
}

export default function Page404() {
  const handleClick = () => {
    navigate('/');
  };

  return (
    <Layout className={ styles.shortHeightContent } showIsi={false}>
        <div className='relative mt-[2.3rem] font-BrillerBold text-[8.5rem] text-center text-gray-100 desktop:mt-[2.3rem] desktop:text-[20rem]'>
          <span>404</span>
          <span className='absolute top-0 right-0 bottom-0 left-0 h-[2em] m-auto font-BrillerBold text-[1.6rem] text-blue-600 desktop:text-[2.5rem] desktop:h-[2.7em]'>
            NOT FOUND
          </span>
        </div>
      <div className={ styles.content }>
        <GlobalWrapper className={'flex-1 w-full mobileWide'}>
          <div className={'mr-[2.6rem] tablet:mr-0'}>
            <div className='font-MontserratRegular text[1.6rem] text-center pt-[3rem] leading-[1.18]'>
              We’re sorry. The page you are looking<br className='desktop:hidden'/> for does
              not exist, may have been <br className='desktop:hidden'/>moved,
              or was removed.
            </div>
            <div className='flex justify-center mt-[2rem] desktop:mt-[8.7rem]'>
              <Button onClick={handleClick}>
                <span>back to home</span>
              </Button>
            </div>
            <Footnotes className={'mt-[4.8rem] pb-[4.9rem] desktop:mt-[11.4rem] desktop:pb-[4.2rem]'} items={[
              {
                bullet: '*',
                text: <span>LOQTORZI™ is used in combination with chemotherapy medicines cisplatin and gemcitabine as your first treatment when your NPC has spread to other parts of your body (metastatic) or has returned (recurrent) in nearby tissues (locally advanced).</span>
              },
              {
                bullet: <sup>†</sup>,
                text: <span>LOQTORZI™ may be used alone to treat your NPC when it has returned and cannot be removed with surgery or has spread (metastatic), and you received chemotherapy that contains platinum, and it did not work or is no longer working.</span>
              }
            ]} />
          </div>
        </GlobalWrapper>
      </div>
    </Layout>
  );
}